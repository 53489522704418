// simple date and hour format
export default function (date, withSeconds) {
	const c = new Date(Date.parse(date));
	let result =
		c.getFullYear() +
		"/" +
		(c.getMonth() + 1).toString().padStart(2, 0) +
		"/" +
		c.getDate().toString().padStart(2, 0) +
		" @ " +
		c.getHours().toString().padStart(2, 0) +
		":" +
		c.getMinutes().toString().padStart(2, 0);
	if (withSeconds) {
		result = result + ":" + c.getSeconds().toString().padStart(2, 0);
	}
	return result;
}
